import $ from 'jQuery';
global.$ = global.jQuery = $;
global._isSP = undefined;

class JSONReader {
  constructor(container, type){
    this.container = $(container);
    this.type = type;
    this.url = "";
    this.parse = {};
    this.count = 0;
    this.currentCount = 0;
    this.xhr = new XMLHttpRequest();
  }

  read(url, max, callback){
    console.log("JSONReader.read");
    var _this = this;
    var _max, _callback;
    _this.url = url;

    if(typeof(max) === "function"){
      _callback = max;
      _max = null;
    }else{
      _callback = callback;
      _max = max;
    }

    var _xhr = this.xhr;
    _xhr.open("GET", this.url);

    _xhr.onload = function(){
      _this.parse = JSON.parse(_xhr.response);

      if(_this.type === "event"){
        _this.outputEvent(_max, _callback);
      }else if(_this.type === "news"){
        _this.outputNews(_max, _callback);
      }else if(_this.type === "promo"){
        _this.outputMV(_max, _callback);
      }else if(_this.type === "calendar"){
        _this.outputCalendar(_callback);
      }
    };
    _xhr.onerror = function(){};

    _xhr.send();
  }

  outputEvent(max, callback){
    console.log("outputEvent");
    var _this = this;
    var _count = 0;
    var _articles = this.parse.articles;

    var _arr = [];
    for(var key in _articles){
      _arr.push(_articles[key]);
    }

    _arr.sort(function(a,b){
      if(a.date > b.date) return -1;
      if(a.date < b.date) return 1;
      return 0;
    });

    for(var i = 0; i < _arr.length; i++){
      if(_count >= max){
        return;
      }
      var _id, _type, _link, _thumb, _categoryClass, _categoryName, _txt, _date;
      var _articleObj = _arr[i];

      _type = _articleObj["type"];
      _link = _articleObj["url"];
      _thumb = _articleObj["thumb_url"];
      _categoryClass = _articleObj["category"]["class"];
      _categoryName = _articleObj["category"]["name"];
      _txt = _articleObj["text"];
      _date = _articleObj["date"];

      var _$box = $("<div class='event-box event-" + _type + "'></div>");
      var _$a = $("<a href='" + _link + "' target='_blank'>");
      var _$thumb = $("<div class='event--thumb'><img src='" + _thumb + "' alt='' width='320' height='180'></div>");
      var _$info = $("<div class='event-info'><p class='event--txt'>" + _txt + "</p><p class='event-date'><span class='event-date--head'>開催日</span><span class='event-date--txt'>" + _date + "</span></p></div>");

      _$a.append(_$thumb);
      _$a.append(_$info);
      _$box.append(_$a);
      _this.container.append(_$box);

      _count++;
    }

    if(callback){
      callback();
    }
  }

  outputNews(max, callback){
    console.log("outputNews");
    var _this = this;
    var _count = 0;

    var _articles = this.parse.articles;
    var _arr = [];
    for(var key in _articles){
      _arr.push(_articles[key]);
    }

    _arr.sort(function(a,b){
      if(a.date > b.date) return -1;
      if(a.date < b.date) return 1;
      return 0;
    });


    for(var i = 0; i < _arr.length; i++){
      if(_count >= max){
        return;
      }

      var _id, _type, _link, _thumb, _categoryClass, _categoryName, _txt, _date;
      var _articleObj = _arr[i];
      _type = _articleObj["type"];
      _link = _articleObj["url"];
      _thumb = _articleObj["thumb_url"];
      _categoryClass = _articleObj["category"]["class"];
      _categoryName = _articleObj["category"]["name"];
      _txt = _articleObj["text"];
      _date = _articleObj["date"];

      var _$box = $("<div class='news-box news-" + _type + "' data-category='" + _categoryClass + "'></div>");
      var _$a = $("<a href='" + _link + "' target='_blank'>");
      var _$thumb = $("<div class='news--thumb'><img src='" + _thumb + "' alt='' width='320' height='180'></div>");
      var _$info = $("<p class='news-info'><span class='news-info--date'>" + _date + "</span><span class='news-info--category'>" + _categoryName + "</span></p><p class='news--txt'>" + _txt + "</p>");

      _$a.append(_$thumb);
      _$a.append(_$info);
      _$box.append(_$a);
      _this.container.append(_$box);

      _this.currentCount++;
      _count++;
    }

    for(var key in _articles){

    }
    if(callback){
      callback();
    }
  }

  outputMV(max, callback){
    console.log("outputMV");

    var _this = this;
    var _count = 0;

    var _articles = this.parse.promotions;
    for(var key in _articles){
      if(_count >= max){
        return;
      }

      var _id, _link, _blank, _imagePC, _imageSP, _txtBox, _txt1, _txt2, _txt3;
      var _articleObj = _articles[key];
      _id = key;
      _link = _articleObj["url"];
      _blank = _articleObj["_blank"];
      _imagePC = _articleObj["image_pc"];
      _imageSP = _articleObj["image_sp"];
      _txt1 = _articleObj["text1"];
      _txt2 = _articleObj["text2"];
      _txt3 = _articleObj["text3"];

      var _$box = $("<div class='mv-box'></div>");
      var _$visual = $("<div class='mv--visual'><img src='" + _imagePC + "' class='display-pc'><img src='" + _imageSP + "' class='display-sp'></div>");
      var _$a;
      var _$txt;

      if(_txt1 !== ""){
        _txtBox = $("<div></div>");
        _$txt = $("<p class='mv--txt en'><span class='mv--head'>" + _txt1 + "</span></p>");
        _txtBox.append(_$txt);

        if(_txt2 !== ""){
          _$txt.append($("<br class='display-sp'><span class='en'>" + _txt2 + "</span>"));
        }
      }
      if(_txt3 !== ""){
        _txtBox.append($("<p class='mv--description en'>" + _txt3 + "</p>"));
      }

      if(_link !== ""){
        _blank = _blank === "" ? "target='_blank'" : _blank;
        _$a = $("<a href='" + _link + "' " + _blank + "></a>");
        _$a.append(_txtBox);
        _$visual.append(_$a);
      }else{
        _$visual.append(_txtBox);
      }

      _$box.append(_$visual);
      _this.container.append(_$box);

      _this.currentCount++;
      _count++;
    }

    if(callback){
      callback();
    }

  }

  outputCalendar(callback){
    console.log("outputCalendar");

    var _this = this;
    var _count = 0;

    var _calendar = this.parse.calendar;
    for(var key in _calendar){

      var _id, _month, _image;
      var _calendarObj = _calendar[key];
      _id = key;
      _month = new Date(_calendarObj["date"]);
      _image = _calendarObj["image_url"];

      var _$box = $("<div class='swiper-slide' title='" + _calendarObj["date"] + "'></div>");
      var _$visual = $("<img src='" + _image + "' alt='" + _calendarObj["date"] + "'>");
      _$box.append(_$visual);
      _this.container.append(_$box);

      var _calendarYear = _month.getFullYear();
      var _calendarMonth = _month.getMonth();

      var _nowDate = new Date(Date.now());
      var _nowYear = _nowDate.getFullYear();
      var _nowMonth = _nowDate.getMonth();

      _this.currentCount++;
      _count++;

      /*if(_nowYear === _calendarYear && _nowMonth === _calendarMonth){
        var _$visual = $("<img src='" + _image + "' alt='" + _calendarObj["month"] + "'>");
        _this.container.append(_$visual);
        return;
      }*/
    }

    if(callback){
      callback();
    }

  }
}

global.JSONReader = JSONReader;

var Init = function(){

  var $window = $(window);
  var $body = $("body");

  var $header = $("header");
  var $footer = $("footer");


  $header.delay(300).animate({opacity: 1}, 300);
  var $mv = $(".mv");
  var _mvHeight = 0;
  if($mv.length > 0){
    _mvHeight = $mv.outerHeight();
  }else{
    _mvHeight = 400;
  }
  var $navPC = $("nav.nav .display-pc");
  var NavColorChange = function(){
    if(_isSP){
      if($header.hasClass("nav-top")){
        $header.removeClass("nav-top");
      }
    }else{
      if($window.scrollTop() + parseInt($navPC.parent().css("marginTop")) > _mvHeight){
        if($header.hasClass("nav-top")){
          $header.removeClass("nav-top");
        }
      }else{
        if(!$header.hasClass("nav-top")){
          $header.addClass("nav-top");
        }
      }
    }
  };
  $window.on("scroll", NavColorChange);
  NavColorChange();

  var $nav = $("nav.nav");
  var $navButton = $nav.find(".nav--btn");
  var NavOpenClose = function(){
    if(!_isSP){
      $header.removeClass("nav--open");
      $body.removeClass("is-modal");
    }else{
      if($header.hasClass("nav--open")){
        $header.removeClass("nav--open");
        $body.removeClass("is-modal");
      }else{
        $header.addClass("nav--open");
        $body.addClass("is-modal");
      }
    }
  };

  $navButton.on("click", NavOpenClose);

  // ScrollAnimatiopn
  window.AnimationStart = function($container){
    // console.log("AnimationStart");
    var _$wait = $container.find(".wait");
    _$wait.each(function(){
      var __$this = $(this);
      var __delay = __$this.data("delay");
      var __timeout = setTimeout(function(){
        __$this
          .removeClass("wait")
          .addClass("animation");
        clearTimeout(__timeout);
      }, __delay)
    });
  };

  var ResetAnimate = function($container){
    // console.log("ResetAnimate");
    var _$animate = $container.find(".animation");
    var _$animating = $container.find(".animating");
    _$animate.each(function(){
      var __$this = $(this);
      __$this
        .addClass("wait")
        .removeClass("animation");
    });
    _$animating.each(function(){
      var __$this = $(this);
      __$this
        .addClass("waiting")
        .removeClass("animating");
    });

  };

  var setAnimation = function(){
    // console.log("setAnimation:");
    // console.log("scrollTop: " + $(scrollTarget).scrollTop());
    var $section = $(".waiting");
    var sectionLen = $section.length;
    var timing, _height, mainHeight, mainTop;
    _height = $window.height();
    mainTop = 0;
    timing = _height - (_height / 2);

    for(var i = 0; i < sectionLen; i++){
      var $wait = $($section[i]);
      var offsetTop;

      offsetTop = $wait.offset().top;
      var _delay = $wait.data("delay");
      if(_delay === void 0){
        _delay = 0;
      }
      $wait.delay(_delay).queue(function(){
        var __$this = $(this);
        __$this.removeClass("waiting").addClass('animating');
        __$this.dequeue();
      });
    }
  };

  var AnimationScroller = function(){
    setAnimation();
  };

  $window.on("scroll", AnimationScroller);
  AnimationScroller();

  $(".pagetop").on("click", function(){
    $("html, body").stop().animate({scrollTop: 0}, 300);
  });

  var Resize = function(){
    if($window.width() < 751){
      _isSP = true;
    }else{
      _isSP = false;
      NavOpenClose();
    }
  };
  $window.on("resize", Resize);
  Resize();

};

window.addEventListener("load", Init);